// import css
import '../theme/default/agel/airline.scss';

// import images
import banner from '../images/banner/airline.jpg';
import banner_2x from '../images/banner/airline-2x.jpg';
import logo_uzb from "../images/airline-uzb.png";
import logo_uzb_2x from "../images/airline-uzb-2x.png";
import logo_redwings from "../images/airline-redwings.png";
import logo_redwings_2x from "../images/airline-redwings-2x.png";
import logo_aliscargo from "../images/airline-alis.png";
import logo_aliscargo_2x from "../images/airline-alis-2x.png";
import logo_aeroflot from "../images/airline-aeroflot.png";
import logo_aeroflot_2x from "../images/airline-aeroflot-2x.png";

// import js
import React from "react";
import Base from "../components/base";
import {useI18next} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

const Airline = () => {
    const i18n = useI18next(['airline', 'translation']);
    const {t} = i18n;

    return (
        <Base title={t('airline:title')} t={t} i18n={i18n}>
            <main className={'airline'}>
                <div className={'main-banner'}>
                    <img src={banner} srcSet={banner_2x + " 2x"}/>
                </div>
                <h1>{t('airline:title')}</h1>
                <div className={'main-content'}>
                    <ul className={'airline-list'}>
                        <li><img src={logo_uzb}
                                 srcSet={logo_uzb_2x + ' 2x'}/>
                            <div dangerouslySetInnerHTML={{__html: t('airline:routes.hy')}}/>
                        </li>
                        <li><img src={logo_redwings}
                                 srcSet={logo_redwings_2x + ' 2x'}/>
                            <div dangerouslySetInnerHTML={{__html: t('airline:routes.wz')}}/>
                        </li>
                        <li><img src={logo_aliscargo}
                                 srcSet={logo_aliscargo_2x + ' 2x'}/>
                            <div dangerouslySetInnerHTML={{__html: t('airline:routes.cp')}}/>
                        </li>
                        <li><img src={logo_aeroflot}
                                 srcSet={logo_aeroflot_2x + ' 2x'}/>
                            <div dangerouslySetInnerHTML={{__html: t('airline:routes.su')}}/>
                        </li>
                    </ul>
                    <p>{t('airline:desc1')}<br/>
                        <ul>
                            <li>{t('airline:city.mainlandChina')}</li>
                            <li>{t('airline:city.moscow')}</li>
                            <li>{t('airline:city.telAviv')}</li>
                            <li>{t('airline:city.milan')}</li>
                            <li>{t('airline:city.frankfurt')}</li>
                            <li>{t('airline:city.amsterdam')}</li>
                            <li>{t('airline:city.london')}</li>
                            <li>{t('airline:city.kiev')}</li>
                            <li>{t('airline:city.almaty')}</li>
                            <li>{t('airline:city.istanbul')}</li>
                            <li>{t('airline:city.saoPaulo')}</li>
                            <li>{t('airline:city.riga')}</li>
                        </ul>
                        <br/>
                        {t('airline:desc2')}
                    </p>
                </div>
            </main>
        </Base>
    )
}

export default Airline;

export const data = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["translation", "airline"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;